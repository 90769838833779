import React, { Component } from "react";
import Banner from "../../../components/common/Banner";
import Header from "../../../components/common/Header";
import DepartmentSidebar from "../component/DepartmentSidebar";
import Footer from "../../../components/common/Footer";
import NoData from "../../../components/common/NoData";
import Loading from "../../../components/common/Loading";
import { doc, getDoc } from "firebase/firestore";
import { firestore } from "../../../Config/FbConfig";
import { Styles } from "../styles/Faculties";
import { Link } from "react-router-dom";

export default class DepartmentTeachingFaculties extends Component {
  state = { Datas: [] };

  async componentDidMount() {
    //For Teaching
    const docRef = doc(
      firestore,
      this.props.match.params.id,
      "FacultyTeaching"
    );
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      var Data = [];
      Object.entries(docSnap.data()).forEach(([key, value]) => {
        Data.push(
          Object.assign(
            {
              Id: key,
              Priority: value.Priority != undefined ? value.Priority : "999",
            },
            value
          )
        );
      });
      Data.length === 0
        ? this.setState({ Datas: "NO_999" })
        : this.setState({
            Datas: Data.sort(function (a, b) {
              return a.Priority - b.Priority;
            }),
          });
    } else {
      this.setState({
        Datas: "NO_999",
      });
    }
  }

  render() {
    const banner = {
      cse: (
        <Banner
          title="Computer Science and Engineering"
          image="banners/department-cse.jpg"
        />
      ),
      ise: (
        <Banner
          title="Information Science and Engineering"
          image="banners/department-ise.jpg"
        />
      ),
      ai: (
        <Banner
          title="Artificial Intelligence and Machine Learning"
          image="banners/department-ai.jpg"
        />
      ),
      ece: (
        <Banner
          title="Electronics and Communication Engineering"
          image="banners/department-ece.jpg"
        />
      ),
      mech: (
        <Banner
          title="Mechanical Engineering"
          image="banners/department-me.jpg"
        />
      ),
      civil: (
        <Banner title="Civil Engineering" image="banners/department-me.jpg" />
      ),
      eee: (
        <Banner
          title="Electrical and Electronics Engineering"
          image="banners/department-eee.jpg"
        />
      ),
      ete: (
        <Banner
          title="Electronics and Telecommunication Engineering"
          image="banners/department-ete.jpg"
        />
      ),
      eie: (
        <Banner
          title="Electronics and Instrumentation Engineering"
          image="banners/department-eie.jpg"
        />
      ),
      iem: (
        <Banner
          title="Industrial Engineering and Management"
          image="banners/department-iem.jpg"
        />
      ),
      mba: (
        <Banner
          title="Master of Business Administration"
          image="banners/department-mba.jpg"
        />
      ),
      mca: (
        <Banner
          title="Master of Computer Applications (MCA)"
          image="banners/department-mca.jpg"
        />
      ),
      phy: <Banner title="Physics" image="banners/department-phy.jpg" />,
      chem: <Banner title="Chemistry" image="banners/department-che.jpg" />,
      math: <Banner title="Mathematics" image="banners/department-math.jpg" />,
      hum: <Banner title="Humanities" image="banners/department-hum.jpg" />,
    };

    return (
      <div>
        {/* Header */}
        <Header />

        {/* Banner */}
        {banner[this.props.match.params.id]}

        {/* Content */}
        <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-9 content-area order-lg-2">
                <div className="section-title with-desc text-left clearfix">
                  <div
                    className="title-header"
                    style={{ borderTopColor: "red" }}
                  >
                    <h4 className="title">Faculties & Staff</h4>
                  </div>
                </div>
                <div className="ttm-service-description">
                  <Styles>
                    <div className="row">
                      {this.state.Datas.length === 0 ? (
                        <Loading />
                      ) : this.state.Datas === "NO_999" ? (
                        <NoData />
                      ) : (
                        this.state.Datas.map((data, i) => (
                          <div
                            className="col-md-4 ttm-box-col-wrapper d-flex align-items-stretch"
                            key={i}
                            style={{ marginBottom: "1rem" }}
                          >
                            <div className="featured-imagebox featured-imagebox-blog">
                              <div
                                className="featured-thumbnail"
                                style={{ height: "15rem" }}
                              >
                                <img
                                  className="img-fluid"
                                  alt=""
                                  src={data.Image}
                                />
                              </div>
                              <div
                                className="featured-content"
                                style={{ marginTop: "-1.5rem" }}
                              >
                                <div className="post-meta">
                                  <h6
                                    style={{
                                      fontWeight: "bold",
                                      fontSize: "14px",
                                      color: "#98002e",
                                    }}
                                  >
                                    {data.Name}
                                  </h6>
                                  <br />
                                  <small>{data.Designation}</small>
                                </div>
                                <Link
                                  className="ttm-btn"
                                  style={{ fontSize: "10px", color: "black" }}
                                  to={
                                    process.env.PUBLIC_URL +
                                    `/department-teaching-faculties/${this.props.match.params.id}/` +
                                    data.Id
                                  }
                                >
                                  View Details{" "}
                                  <i className="fas fa-angle-double-right" />
                                </Link>
                              </div>
                            </div>
                          </div>
                        ))
                      )}
                    </div>
                  </Styles>
                </div>
              </div>
              <div className="col-lg-3 widget-area">
                <DepartmentSidebar department={this.props.match.params.id} />
              </div>
            </div>
          </div>
        </div>

        {/* Footer */}
        <Footer />
      </div>
    );
  }
}
