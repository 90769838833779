import React, { Component } from "react";
import { Link } from "react-router-dom";
import Banner from "../../../components/common/Banner";
import Header from "../../../components/common/Header";
import DepartmentSidebar from "../component/DepartmentSidebar";
import Footer from "../../../components/common/Footer";

export default class METeaching extends Component {
  render() {
    return (
      <div>
        {/* Header */}
        <Header />

        {/* Banner */}
        <Banner
          title="Mechanical Engineering"
          image="banners/department-me.jpg"
        />

        {/* Content */}
        <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-9 content-area order-lg-2">
                <div className="section-title with-desc text-left clearfix">
                  <div className="title-header">
                    <h4 className="title">Learning Resources</h4>
                  </div>
                </div>
                <div className="ttm-service-description">
                  <p>
                    Wide arrays of learning materials are provided to students
                    in advance for each course to catalyze their learning
                    ability. These resources include syllabus, course materials,
                    and links to digital aids. Click here to access learning
                    resources.
                  </p>
                  <Link
                    className="ttm-btn"
                    style={{
                      fontSize: "1.15rem",
                      paddingTop: "1rem",
                      color: "#00a76a",
                    }}
                    to={
                      process.env.PUBLIC_URL +
                      `/teaching-learning-material/mech`
                    }
                  >
                    Click Here for Learning Resources
                  </Link>
                </div>
                <div className="ttm-service-single-content-area paddingtop-2">
                  <div className="section-title with-desc text-left clearfix">
                    <div
                      className="title-header"
                      style={{ borderTopColor: "red" }}
                    >
                      <h4 className="title">Teaching - Learning</h4>
                    </div>
                  </div>
                  <div className="ttm-service-description">
                    <p>
                      The Department of Mechanical Engineering begins an
                      academic planning with university calendar which depicts
                      the semester beginning, last working day, tentative
                      schedule of practical and theory examination.
                    </p>
                    <h6 className="paddingtop-2">
                      Course Delivery Methods used in Mechanical Engineering
                      Department
                    </h6>
                    <br />
                    <ul className="ttm-list ttm-list-style-icon">
                      <li>
                        <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          Based on the VTU calendar of events, college and
                          department calendar of events will be prepared.
                          College calendar of events consists of the activities
                          planned for the semester which includes internal test
                          dates, total number of working days and holidays etc,
                          while department calendar of events consists of
                          several events to be conducted such as organizing
                          guest lectures, conferences, industrial visits,
                          workshops etc.
                        </span>
                      </li>
                      <li>
                        <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          The Continuous Internal Evaluation (CIE) consists of
                          three tests, the average of three tests was considered
                          and assignments/seminars/quiz will be considered for
                          the award of final CIE marks.
                        </span>
                      </li>
                      <li>
                        <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          The question papers for the semester end examination
                          are set by VTU. The final marks will be awarded by
                          considering Continuous Internal Evaluation (CIE) marks
                          and Semester End Examination (SEE) marks.
                        </span>
                      </li>
                      <li>
                        <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          The Department will identify the bright students and
                          slow learners based on their previous academic
                          performance. Department motivates the slow learners to
                          attend remedial classes and help them to overcome the
                          difficulties. Encouragement is given to the bright
                          students to attend more workshops, STTP’s, conferences
                          and technical talks.
                        </span>
                      </li>
                      <li>
                        <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          The class room lectures are delivered by the faculty
                          through various Pedagogical Initiatives as stated
                          below and are monitored by Senior Professors of IQAC
                          and Head of the Department.
                        </span>
                        <br />
                        <br />
                        <div className="row">
                          <div className="col-6">
                            * Conventional Black Board Method
                            <br />
                            * Power Point Presentations
                            <br />
                            * ICT Methods (Video Lectures /Animations/MOOC)
                            <br />
                            * Encouraging Collaborative Learning
                            <br />
                            * Problem Based Learning
                            <br />
                            * Demonstrations
                            <br />
                          </div>
                          <div className="col-6">
                            * Technical Aptitude (QUIZ)
                            <br />
                            * Mini Projects, Internships and Projects
                            <br />
                            * Technical Presentations and Seminars
                            <br />
                            * Group Discussions
                            <br />
                            * Reading and Discussion of Research Article
                            <br />
                            * Providing study material through BIT website
                            <br />
                          </div>
                        </div>
                      </li>
                    </ul>
                    <div className="section-title with-desc text-left clearfix">
                      <div
                        className="title-header paddingtop-2"
                        style={{ borderTopColor: "red" }}
                      >
                        <h5 className="title">
                          The following flow chart depicts the process followed
                          to improve quality of teaching and learning in the
                          department
                        </h5>
                      </div>
                      <img
                        className="img-fluid"
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/departments/mech/flowchart.jpg"
                        }
                        title="single-img-twenty"
                        alt="single-img-twenty"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 widget-area">
                <DepartmentSidebar department="mech" />
              </div>
            </div>
          </div>
        </div>

        {/* Footer */}
        <Footer />
      </div>
    );
  }
}
